import { isNumber } from "lodash";
import { Euler, Quaternion } from "three";
import {
  BaseCaptureTreeEntity,
  ClusterEntity,
} from "@custom-types/capture-tree-types";
import { getTopLevelClusterEntities } from "@utils/capture-tree-utils";
import { EventProps } from "@faro-lotv/foreign-observers";
import { convertToDateString, generateGUID } from "@faro-lotv/foundation";
import {
  CaptureTreeEntityType,
  CaptureTreePointCloudType,
  CreateScanEntitiesParams,
} from "@faro-lotv/service-wires";
import { UploadedFile } from "@custom-types/file-upload-types";
import { DEFAULT_POSE_PARAM } from "@src/constants/capture-tree-constants";
import { ReadLsDataV2Response, Scan } from "@api/stagingarea-api/stagingarea-api-types";

/** Els cluster name prefix */
const ELS_CLUSTER_NAME_PREFIX = "Blink Scans_";

/** Regular expression to validate the name of an ELS cluster. It should have the format "ELS Scans_{mm/dd/yyyy}" */
const ELS_CLUSTER_NAME_REGEX = new RegExp(
  `^${ELS_CLUSTER_NAME_PREFIX}\\d{2}\\/\\d{2}\\/\\d{4}$`
);

/** Regular expression to extract the UUID (usually UUIDv4) from "...UUID.gls". */
const ENDSWITH_UUID_GLS_REGEX = /([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}).gls$/i;

/** @returns True if it's a *.gls file (ELS/Blink scan). */
export function isGLS(fileName: string): boolean {
  return fileName.toLowerCase().endsWith(".gls");
}

/** @returns True if it's the "index-v2" file from the LsDataV2 format. */
export function isIndexV2(fileName: string): boolean {
  return fileName.toLowerCase() === "index-v2";
}

/** @returns True if it's a file "ls-data/objects/[0-9a-f]" from the LsDataV2 format. */
export function isLsDataObject(file: File): boolean {
  // According to the FW team, there can be max. 16 files "ls-data/objects/[0-9a-f]",
  // and no multi-digit filenames.
  // If we have the relative path available, use it to better filter out irrelevant files,
  // since file.name = [0-9a-f] is quite generic.
  const isLsDataObject = file.webkitRelativePath ?
    file.webkitRelativePath.toLowerCase().includes("ls-data/objects/") :
    undefined;

  return isLsDataObject !== false && (/^[0-9a-f]$/i).test(file.name);
}

/** @returns Info about files being added or uploaded, for tracking. */
export function filesInfoForTracking(files: File[]): EventProps {
  return {
    filesGLS: files.filter((file) => isGLS(file.name)).length,
    filesIndexV2: files.filter((file) => isIndexV2(file.name)).length,
    filesOther: files.filter((file) => !isGLS(file.name) && !isIndexV2(file.name)).length,
  };
}

/** @returns Info about the data in the LsDataV2 package, for tracking. */
export function lsDataV2InfoForTracking(lsDataV2?: ReadLsDataV2Response): EventProps {
  return {
    hasLsDataV2: !!lsDataV2,
    lsScans: lsDataV2?.scans?.length ?? 0,
    lsClusters: lsDataV2?.clusters?.length ?? 0,
    lsEdges: lsDataV2?.edges?.length ?? 0,
  };
}

/**
 * @param name string to validate
 * @returns Whether the passed string is a valid name for a ELS cluster
 */
export function isValidElsClusterName(name: string): boolean {
  return ELS_CLUSTER_NAME_REGEX.test(name);
}

/**
 * @returns a valid ELS cluster name of format "ELS Scans_{mm/dd/yyyy}"
 */
export function getElsClusterName(): string {
  const date = convertToDateString(new Date().toISOString(), "en-US");
  return `${ELS_CLUSTER_NAME_PREFIX}${date}`;
}

/**
 * @param entities The capture tree entities
 * @returns The first found "ELS" cluster entity or undefined if not found
 * A cluster is considered the "ELS" cluster if:
 * - its a top-level cluster and
 * - it has a valid name for an ELS cluster
 */
export function getElsClusterEntity<T extends BaseCaptureTreeEntity>(
  entities: T[]
): ClusterEntity<T> | undefined {
  const topLevelClusters = getTopLevelClusterEntities(entities);
  return topLevelClusters.find((cluster) =>
    isValidElsClusterName(cluster.name)
  );
}

interface GetScanEntitiesParamsProps {
  /** List of successfully uploaded scans to add */
  uploadedScans: UploadedFile[];

  /** ID of the revision cluster entity where the scans will be added */
  revisionClusterEntityId: string;

  /** Map from filename (*.gls) to scan metadata from LsDataV2. */
  scansByFilename: Record<string, Scan>;
}

/**
 * @returns An array of params required to create capture tree scan entities from the passed uploaded scans
 */
export function getCreateScanEntitiesParams({
  uploadedScans,
  revisionClusterEntityId,
  scansByFilename,
}: GetScanEntitiesParamsProps): CreateScanEntitiesParams["requestBody"] {
  return uploadedScans.map((uploadedScan) => {
    // If the user uploaded only the GLS files, without LsDataV2, scansByFilename is empty.
    // If the user added an extra GLS file which is not in LsDataV2, the scan will be uploaded but missing in scansByFilename.
    // -> We should allow and handle the case `scan === undefined`.
    const scan: Scan | undefined = scansByFilename[uploadedScan.fileName];
    const translation = scan?.trafo?.translation || [];
    const rotationAngles = scan?.trafo?.rotationAngles || [];
    const pose = {
      pos: DEFAULT_POSE_PARAM.pos,
      rot: DEFAULT_POSE_PARAM.rot,
    };
    if (isNumber(translation[0]) && isNumber(translation[1]) && isNumber(translation[2])) {
      pose.pos = {
        x: translation[0],
        y: translation[1],
        z: translation[2],
      };
    }
    if (isNumber(rotationAngles[0]) && isNumber(rotationAngles[1]) && isNumber(rotationAngles[2])) {
      // LsDataV2 does not yet properly specify its rotations. Our code here is at least consistent with the
      // Stream app, and produces correct results for usual cases (rotation around Z axis; XY almost 0°).
      // https://faro01.atlassian.net/wiki/spaces/FW/pages/4316332063/Unified+representation+of+scan+scanner+poses
      const euler = new Euler(rotationAngles[0], rotationAngles[1], rotationAngles[2], "XYZ");
      const quat = new Quaternion().setFromEuler(euler);
      pose.rot = {
        x: quat.x,
        y: quat.y,
        z: quat.z,
        w: quat.w,
      };
    }

    let externalId = scan?.uuid;
    if (!externalId) {
      // Check if it ends with "UUID.gls".
      const matches = uploadedScan.fileName.match(ENDSWITH_UUID_GLS_REGEX);
      if (matches?.[1]) {
        externalId = matches[1].toLowerCase();
      } else {
        // The type of `PointCloudParams.externalId` is wrong, since the field is mandatory.
        // By generating a GUID instead of a UUIDv4, one has at least a chance to identify that it's a generated ID:
        // uuid[19] must be [89ab] for a UUID, but seems arbitrary for a GUID.
        externalId = generateGUID();
      }
    }

    return {
      parentId: revisionClusterEntityId,
      type: CaptureTreeEntityType.elsScan,
      name: scan?.name || uploadedScan.fileName,
      pose,
      pointClouds: [
        {
          externalId,
          type: CaptureTreePointCloudType.elsRaw,
          uri: uploadedScan.downloadUrl,
          md5Hash: uploadedScan.md5,
          fileSize: uploadedScan.fileSize,
          fileName: uploadedScan.fileName,
        },
      ],
    };
  });
}

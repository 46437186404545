import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { bytesToMegaBytes } from "@utils/file-utils";
import { FaroIconButton } from "@components/common/faro-icon-button";
import CancelIcon from "@assets/icons/Cancel.svg?react";
import InfoIcon from "@assets/icons/new/info_20px.svg?react";
import ScanDataIcon from "@assets/icons/new/scan-data_24px.svg?react";

interface Props {
  /** The name of the file */
  fileName: string;

  /** The translatable title of the file, to be displayed instead of the fileName. Also determines the icon. */
  fileTitle?: "Scan Metadata";

  /** The size of the file in byte */
  fileSize: number;

  /** True if the file is valid */
  isValid?: boolean;

  /** Callback function to remove file from list */
  onDelete?(): void;
}

/** Class name for the scan data file size */
const scanDataFileSizeClass = "scanDataFileSizeClass";

/** Class name for the scan data remove button */
const scanDataRemoveClass = "scanDataRemoveClass";

/** Renders scan data files  */
export function ScanDataFile({
  fileName,
  fileTitle,
  fileSize,
  isValid = true,
  onDelete,
}: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: `1px solid ${
          isValid ? sphereColors.gray200 : sphereColors.red300
        }`,
        background: sphereColors.gray50,
        padding: "6px",
        mb: "2px",
        "&:hover": {
          [`& .${scanDataFileSizeClass}`]: {
            display: "none",
          },
          [`& .${scanDataRemoveClass}`]: {
            display: "block",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          width: "calc(100% - 70px)",
        }}
      >
        {/* Icon */}
        <Box sx={{ width: "24px", height: "24px" }}>
          {/* The info icon is aligned slightly different, so we need a margin. */}
          {fileTitle === "Scan Metadata" ?
            <InfoIcon style={{marginTop: "2px", marginLeft: "2px"}} /> :
            <ScanDataIcon />
          }
        </Box>

        {/* File name */}
        <Typography
          sx={{
            fontSize: DEFAULT_TEXT_FONT_SIZE,
            color: sphereColors.gray800,
            ...withEllipsis,
          }}
        >
          {fileTitle ? fileTitle : <var>{fileName}</var>}
        </Typography>
      </Box>

      {/* File Size */}
      <Typography
        className={scanDataFileSizeClass}
        sx={{ fontSize: "10px", color: sphereColors.gray800 }}
      >
        {fileSize >= 0 ? <var>{bytesToMegaBytes(fileSize)} MB</var> : null}
      </Typography>

      <Box
        className={scanDataRemoveClass}
        sx={{
          display: "none",
        }}
      >
        <FaroIconButton
          component={CancelIcon}
          onClick={onDelete}
          buttonSize="22px"
          iconSize="16px"
          isDisabled={!onDelete}
        />
      </Box>
    </Box>
  );
}
